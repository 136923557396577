export const companyLinks = [
  { text: 'Home', url: '/' },
  { text: 'About Us', url: '/about-us' },
  { text: 'Research', url: '/research' },
  { text: 'Webinars', url: '/webinars' },
  { text: 'Careers', url: '/careers' },
  { text: 'Contact Us', url: '/contact-us' }
]

export const policiesLinks = [
  { text: 'Cookie Policy', url: '/cookie-policy' },
  { text: 'Privacy Policy', url: '/privacy-policy' },
  { text: 'Terms of Service', url: '/terms-of-service' }
]