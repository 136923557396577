import React from 'react'
import Header from '../../src/components/Header/Header'
import Footer from '../../src/components/Footer'
import backgroundPattern from '../../src/assets/images/backgroundPattern_compressed.svg'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

const Layout = ({ children }) => {
  return (
    <Grid
      container
      flexDirection='column'
      sx={{ minHeight: '100vh' }}
      justifyContent='space-between'
    >
      <Grid item>
        <Header />
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        <Box
          sx={(theme) => ({
            marginTop: '64px',
            background: `url(${backgroundPattern})`,
            backgroundRepeat: 'repeat',
            paddingBottom: theme.spacing(20),
            '@media (max-width:1200px)': {
              paddingBottom: theme.spacing(16)
            },
            '@media (max-width:900px)': {
              paddingBottom: theme.spacing(12)
            },
            '@media (max-width: 600px) and (orientation: landscape)': {
              marginTop: '48px'
            },
            '@media (max-width:600px)': {
              marginTop: '56px',
              paddingBottom: theme.spacing(9)
            }
          })}
        >
          {children}
        </Box>
      </Grid>
      <Grid item>
        <Footer />
      </Grid>
    </Grid>
  )
}

export default Layout
