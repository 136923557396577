import React from 'react'
import { Helmet } from 'react-helmet'
import favicon from '../../src/assets/favicons/favicon.ico'
import faviconAti from '../../src/assets/favicons/apple-touch-icon.png'
import favicon32 from '../../src/assets/favicons/favicon-32x32.png'
import favicon16 from '../../src/assets/favicons/favicon-16x16.png'
import faviconMask from '../../src/assets/favicons/safari-pinned-tab.svg'

const Metadata = () => {
  return (
    <Helmet>
      <meta charset='utf-8' />
      <meta http-equiv='X-UA-Compatible' content='IE=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta property='og:title' content='Gematria' />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://gematria.tech' />
      <title>Gematria Tech</title>
      <link rel='icon' href={favicon} />
      <link rel='apple-touch-icon' sizes='120x120' href={faviconAti} />
      <link rel='icon' type='image/png' sizes='32x32' href={favicon32} />
      <link rel='icon' type='image/png' sizes='16x16' href={favicon16} />
      <link rel='mask-icon' href={faviconMask} color='#ffffff' />
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta name='theme-color' content='#ffffff' />
    </Helmet>
  )
}

export default Metadata
