/* eslint-disable import/prefer-default-export */
import React from 'react'
import Provider from './Provider'
import Layout from './Layout'
import '@fontsource/montserrat'

export const wrapRootElement = ({ element }) => {
  return (
      <Provider>{element}</Provider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
