import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let theme = createTheme({
  palette: {
    mode: 'dark',
    type: 'dark',
    background: {
      default: 'rgb(0,0,0)',
      paper: '#121212'
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    primary: {
      main: 'rgb(255, 255, 255)'
    },
    secondary: {
      main: 'rgb(23, 205, 76)'
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)'
    },
    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      selected: 'rgba(255, 255, 255, 0.16)',
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)'
    }
  },
  /* : {
    mode: 'light',
    type: 'light',
    primary: {
      main: 'rgb(33, 37, 41)'
    },
    secondary: {
      main: 'rgb(23, 205, 76)'
    },
    sectionGrid: {
      text: '#fff',
      background: 'rgb(33, 37, 41)'
    }
  }, */ typography: {
    fontFamily: 'montserrat'
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          alignItems: 'center'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          color: '#fff'
        }
      }
    }
  }
})

theme = responsiveFontSizes(theme)
export default theme
