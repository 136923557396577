import React from 'react'
import Link from '../Link'
import { companyLinks } from '../../assets/data/pagesList'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'

const NavBar = ({ menu, showMenu }) => {
  return (
    <Toolbar
      sx={{
        width: menu ? '37%' : 0,
        height: '100vh',
        position: 'absolute',
        padding: menu ? 'auto' : '0 !important',
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
        transition: 'all 0.5s',
        right: 0,
        top: 0,
        '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)':
          {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            backdropFilter: 'blur(20px)'
          },
        '@media (min-width: 1024px)': {
          width: 'auto',
          height: 'auto',
          position: 'relative',
          backgroundColor: 'inherit',
          backdropFilter: 'none',
          right: 'auto !important',
          top: 'auto !important'
        }
      }}
    >
      <MenuList
        sx={{
          '@media(min-width: 1024px)': { display: 'flex', flexDirection: 'row' }
        }}
      >
        {companyLinks.map((page, index) => (
          <MenuItem key={index}>
            <Link
              onClick={() => showMenu(false)}
              to={page.url}
              underline='none'
              activeStyle={{
                color: 'rgb(23, 205, 76)'
              }}
            >
              {page.text}
            </Link>
          </MenuItem>
        ))}
      </MenuList>
    </Toolbar>
  )
}

export default NavBar
