import React from 'react'
import Link from './Link'
import { companyLinks, policiesLinks } from '../assets/data/pagesList'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

const Footer = () => {
  return (
    <Grid
      container
      spacing={5}
      sx={{
        p: 3,
        backgroundColor: (theme) => theme.palette.background.default,
        '@media (max-width: 600px)': {
          flexDirection: 'column',
          p: 2
        }
      }}
    >
      <Grid item>
        <Stack direction='column'>
          <Typography variant='h6' color='text.primary'>
            © {new Date().getFullYear()} Gematria Technologies Ltd.{' '}
          </Typography>
          <Typography variant='body2'>
            Build custom market signals and ESG scores.
          </Typography>
        </Stack>
      </Grid>
      <Grid item>
        <Stack direction='row' spacing={5}>
          <Stack direction='column'>
            <Typography variant='h6' color='text.primary'>
              Company
            </Typography>
            {companyLinks.map((link) => (
              <Link
                key={link.text}
                to={link.url}
                sx={{ width: 'fit-content' }}
              >
                <Typography variant='body2'>{link.text}</Typography>
              </Link>
            ))}
          </Stack>
          <Stack direction='column'>
            <Typography variant='h6' color='text.primary'>
              Policies
            </Typography>
            {policiesLinks.map((link) => (
              <Link
                key={link.text}
                to={link.url}
                target='_blank'
                sx={{ width: 'fit-content' }}
              >
                <Typography variant='body2'>{link.text}</Typography>
              </Link>
            ))}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default Footer
