import React, { useState } from 'react'
import Link from '../Link'
import MuiLink from '@mui/material/Link'
import NavBar from './NavBar'
import logo from '../../assets/images/logo.svg'
import LinkedIn from '@mui/icons-material/LinkedIn'
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/icons-material/Menu'
import Close from '@mui/icons-material/Close'
import ClickAwayListener from '@mui/base/ClickAwayListener'

const Header = () => {
  const [menu, showMenu] = useState(false)

  return (
    <AppBar elevation={0}>
      <Toolbar>
        <Link to='/' sx={{ lineHeight: 0 }}>
          <Box
            src={logo}
            component='img'
            sx={{
              height: '35px',
              '@media (max-width: 600px)': {
                height: '25px'
              }
            }}
          />
        </Link>
        <Grid container alignItems='center' sx={{ width: 'fit-content' }}>
          <Grid item justify='center' align='center'>
            <Link
              to='/contact-us'
              sx={{ color: 'white', mr: 2 }}
              underline='none'
            >
              <Button
                variant='contained'
                color='secondary'
                size='small'
                disableElevation={true}
                sx={{
                  display: 'inline-flex',
                  '@media(min-width: 1100px)': { display: 'none' }
                }}
              >
                Demo
              </Button>
              <Button
                variant='contained'
                color='secondary'
                size='small'
                disableElevation={true}
                sx={{
                  display: 'none',
                  '@media(min-width: 1100px)': { display: 'inline-flex' }
                }}
              >
                Request My Demo
              </Button>
            </Link>
          </Grid>
          <Grid item justify='center' align='center'>
            <MuiLink href='https://www.linkedin.com/company/gematria-technologies/'>
              <IconButton sx={{ zIndex: 100 }}>
                <LinkedIn />
              </IconButton>
            </MuiLink>
          </Grid>
          <ClickAwayListener onClickAway={() => menu && showMenu(false)}>
            <Grid item>
              <IconButton
                sx={{
                  display: 'inline-flex',
                  '@media (min-width: 1024px)': { display: 'none' },
                  zIndex: 100
                }}
                onClick={() => showMenu(!menu)}
                aria-label='menu'
              >
                {menu ? <Close /> : <Menu />}
              </IconButton>
              <NavBar menu={menu} showMenu={showMenu} />
            </Grid>
          </ClickAwayListener>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Header
